<template>
  <v-container>
    <!-- <pre>{{ warehousesInventory }}</pre> -->
    <v-row
      justify="center"
    >
      <v-col
        cols="12"
      >
        <base-material-card
          color="warning"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Inventario
            </div>

            <div class="text-text-subtitle-1 font-weight-light">
              Inventario disponible
            </div>
          </template>

          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busca por cualquier campo"
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="warehousesInventory"
            :loading="fechingWarehouseInventory"
            :search="search"
            loading-text="Cargando... Por favor, espere"
            no-data-text="No hay datos disponibles"
            :footer-props="{
              'items-per-page-text': 'Filas por página',
              pageText: '{0}-{1} de {2}'
            }"
          />
        <!--
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
            />
          </v-card-text> -->
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  export default {
    name: 'PrincipalInventory',

    data () {
      return {
        search: '',
        headers: [
          {
            sortable: false,
            text: 'Codigo',
            value: 'codigo',
          },
          {
            sortable: false,
            text: 'Nombre',
            value: 'description',
          },
          {
            sortable: false,
            text: 'Cantidad Total',
            value: 'quantity_total',
          },
          {
            sortable: false,
            text: 'Cantidad Disponible',
            value: 'quantity_available',
          },
          {
            sortable: false,
            text: 'Cantidad Concesionado',
            value: 'quantity_concession',
          },
          {
            sortable: false,
            text: 'Unidad',
            value: 'salesUnit',
            align: 'right',
          },
        ],
      }
    },

    computed: {
      ...mapState([
        'fechingWarehouseInventory',
        'warehousesInventory',
      ]),
    },

    async created () {
      await this.fetchWarehouseInventory({ id: this.$route.params.id })
    },

    methods: {
      ...mapActions([
        'fetchWarehouseInventory',
      ]),
    },

  }
</script>
